.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #333;
  opacity: 0.4;
  z-index: 1000;
}

.modalContainer {
  min-height: 100px;
  min-width: 300px;
  max-width: 98vw;
  width: 400px;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: aliceblue;
  z-index: 1001;
  display: flex;
  flex-flow: column;
  align-items: center;

  border-radius: 10px;
  box-shadow: 4px 4px 8px 0 rgb(34 60 80 / 20%);
}

.modalContainerMoreInfo {
  padding: 20px 0;
}

.modalTitleText {
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.modalContent {
  padding-top: 7px;
  width: 100%;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.modalButtonBlock {
  margin-top: 10px;
  width: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.modalButtonBlock > button {
  min-width: 100px;
  display: inline-block;
  align-items: center;
  margin: 4px;
}
