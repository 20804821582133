.buttonSize {
  width: 100px;
}

.subHeaderContainer {
  position: absolute;
  top: 15px;
}

.position__center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position__vertical {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.position__horizontal {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.selectInstanceLine {
  font-size: 14px;
  height: 56px;
  display: flex;
  flex-wrap: wrap;
  place-content: center center;
  align-items: center;
}

.button__icon {
  padding-bottom: 2px;
}

.circle__green::before,
.circle__red::before,
.circle__gray::before,
.circle__yellow::before {
  content: '● ';
  font-size: 20px;
}

.circle__green::before {
  color: green;
}

.circle__red::before {
  color: red;
}

.circle__gray::before {
  color: gray;
}

.circle__yellow::before {
  color: yellow;
}

.moreInfoLineForModal {
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
}

.DetailsLine {
  display: flex;
  justify-content: space-between;
}

.DetailsLine:hover{
  background: #00000010;
}

.moreInfoLineForScheduleModal {
  display: flex;
  justify-content: space-between;
}

.moreInfoLineForModal__Key{
  text-transform: capitalize;
}

.moreInfoLineForModal:hover {
  background: #00000010;
}

.gapForm > div:not(:last-child) {
  margin-bottom: 20px;
}

.modalContent__gridContainer {
  margin: 8px 0;
  max-height: 90%;
  overflow-y: auto;
}

.paper_style {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  padding: 16px;
}

.link_text {
  color: #2e3d6a;
  text-decoration: underline;
  cursor: pointer;
}

.link_text:hover {
  color: #0000fd;
}

.Status {
  text-align: right;
  padding-right: 30px ;
}

.Device {
  padding-left: 30px ;
}

.AccordionItem:hover{
  background: #00000010;
}

.private_icon {
  color: rgb(0 0 255);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.helperTextItem {
  margin-top: 5px;
  margin-left: 5px;
  min-height: 20px;
  color: #a00;
  font-size: 14px;
}
