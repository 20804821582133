.navbar-header > img {
  height: 24px;
}

.navbar-title-color {
  background: #293760;
}

.navbar-title-label {
  font-size: 24px;
  color: white;
}

.navbar-group-item {
  display: block;
  box-sizing: border-box;
  padding: 8px 8px 8px 55px;
  font-size: 18px;
  color: white;
  height: 40px;
  cursor: pointer;
  white-space: nowrap;
}

.navbar-group-item:hover {
  color: yellow;
}

.navbar-group-item:active {
  position: relative;
  top: 2px;
}

.navbar-group-item-selected {
  font-weight: 600 !important;
}

.mainContent {
  padding: 20px 0 20px 20px;
  width: 100%;
}

.content-body {
  width: 100%;
  height: calc(100vh - 110px);
}

.icon-button {
  display: inline-block;
  position: relative;
  top: -2px;
}

.content-rows {
  padding-bottom: 20px;
  padding-right: 20px;
  max-height: 95%;
  overflow-y: scroll;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(224 224 224 / 100%);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(224 224 224 / 100%);
}
