.navbarWrapper {
  min-width: 210px;
  width: 210px;
  max-width: 210px;
  height: 100%;
}

.navbarBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 299;
}

.navbarWrapper_mobile {
  min-width: 0;
  width: 0;
  max-width: 0;
  height: 100%;
}

.navbarBody {
  position: relative;
  transform: translateX(-220px);
  transition:
    transform 100ms ease-in-out,
    opacity 200ms;
  width: 100%;
  height: 100%;
  z-index: 300;
}

.navbarBody_active {
  transform: translateX(0);
}

.navbarContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #2e3d6b;
  padding-bottom: 3rem;
  transition: left 0.15s ease;
  overflow-y: auto;
  z-index: 999;
}

.navbarHeader {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
  text-overflow: ellipsis;
}

.navbarTopHeader {
  padding-left: 13px;
  background: #293760;
  font-weight: 600;
  font-family:
    Apercu,
    Avenir,
    -apple-system,
    BlinkMacSystemFont,
    "Helvetica Neue",
    Helvetica,
    Calibri,
    Roboto,
    Arial,
    sans-serif;
}

.navbarTopHeader_mobile {
  padding-left: 22px;
  height: 68px;
}

.navbarHeader > img {
  height: 24px;
}

.navbarHeaderText {
  font-size: 24px;
  color: white;
}

.NavBarGroupTitle {
  font-size: 20px;
  color: white;
  cursor: pointer;
  white-space: nowrap;
}

.NavBarGroupItem {
  box-sizing: border-box;
  padding-left: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  color: white;
  height: 40px;
  cursor: pointer;
  white-space: nowrap;
}

.NavBarGroupItem:hover {
  background: #293760;
}

.NavBarGroupItem:active {
  position: relative;
  padding-top: 2px;
}

.NavBarGroupItem__selected {
  background: #293760;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.hamMenu {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 5px;
  z-index: 301;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #293760;
  border-radius: 10px;
}

.hamLine {
  background-color: white;
  border-radius: 6px;
  width: 30px;
  height: 4px;
  transition: transform 100ms ease-in-out;
}

.hamLine1 {
  transform-origin: 0 0;
}

.hamLine3 {
  transform-origin: 0 100%;
}

.hamLine1Active {
  display: block;
  transform: rotate(45deg);
}

.hamLine2Active {
  opacity: 0;
}

.hamLine3Active {
  display: block;
  transform: rotate(-45deg);
}

.navbarTopHeader__onClick {
  cursor: pointer;
}
