.routerPageBody {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
}

.mainPageBody {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
}
